<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
    <div class="container">

      <div id="passwordreset">

        <p class="logo"><a href="https://flowe.io"><img src="/static/img/flowe.png" class="imglogo"></a></p>

        <div class="pagetitle">
          <h2 class = "text-center" v-if="setpassword">Create a password</h2>
          <h2 class = "text-center" v-else>Change password </h2>
        </div>

        <p v-if="setpassword" class="text-center pagetitle">For added security please create a backup password. You will be able to sign in using {{ useremail }} and this password even if you lose access to your SSO-account. And don’t worry, you can change the password and your registered email address from your profile settings in Flowe.</p>

        <p class="text-center" v-if="!emailisvalid">We're sorry but this link has expired. Click <a href="/forgot-password" class="text-primary">here</a> to request a new one.</p>    
    
        <div v-if="emailisvalid">
          <form onsubmit="return false" v-if="!passwordresetok">
            <div class="form_wrapper">
              {{ password.error }}
              <div class="form-group">
                <label>New password</label>
                <input type="Password" class="hide" value="" name="dummypassword2" id="inputPassword23" autocomplete="off">
                <input type="Password" class="form-control" value="" name="password2" id="inputPassword2" autocomplete="off" placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password';" v-model="password.password2.value">

               <popoverpassword target="inputPassword2" :check="password.password2.value" :mobiledevise ="mobiledevise" />

                <p class="text-danger" role="alert" v-if="password.password2.error!='' || password.error != ''" >
                  <span v-if="password.password2.error=='fieldrequired'">This field is required</span>
                  <span v-if="password.error=='passwordmin'">Password must be at least 8 characters</span>
                  <span v-if="password.error=='passwordparam'">Password must have at least one lowercase character, one uppercase character, and one number.</span>

                </p>
              </div>
              <div class="form-group">
                <label>Retype new password</label>
                <input type="Password" class="form-control" value="" name="password3" autocomplete="off" v-model="password.password3.value" placeholder="Confirm password" onfocus="this.placeholder = '';" onblur="this.placeholder = 'Confirm password';">
                <p class="text-danger" role="alert" v-if="password.password3.error!='' || password.error != ''" >
                  <span v-if="password.password3.error=='fieldrequired'">This field is required</span>
                  <span v-if="password.password3.error=='passwordmismatched'">New password and confirm password do not match</span>
                  <span v-if="password.password3.error=='passwordreused'">New password must be different from the old one. Please choose a different password.</span>
                </p>
              </div>
              <div class="form-group" style = "padding-top: 20px" v-if="setpassword">
                <button v-if="status=='idle'" type="submit" class="btn btn-block btn-lg btn-primary" v-on:click.prevent="passwordSetdo()">Create a password</button>
                <button v-if="status!='idle'" type="submit" class="btn btn-block btn-lg btn-primary" disabled="disabled">Create a password</button>
              </div>
              <div class="form-group" style = "padding-top: 20px" v-else>
                <button v-if="status=='idle'" type="submit" class="btn btn-block btn-lg btn-primary" v-on:click.prevent="passwordResetdo()">Change password</button>
                <button v-if="status!='idle'" type="submit" class="btn btn-block btn-lg btn-primary" disabled="disabled">Change password</button>
              </div>

            </div>
          </form>     
<!-- 
          <div class="forgotpasswordpage" v-if="passwordresetok">

            <div class="pagetitle">
              <h2>Success!</h2>
              <p class="text-center">Your password has been changed and you<br>
              will be redirected to the dashboard shortly.</p>
              <p class="text-center"><a href="/sign-in">(Click here if that doesn't happen)</a></p>
            </div>
          </div> -->


        </div>
      </div>
    </div>
  </div>
</template>	
<style type="text/css" scoped>
:root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
}



h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

p.logo .imglogo{ 
  width: 200px; 
}
p.logo{
  text-align: center;
  width: 100%;
  margin: auto;
}


</style>
<script type="text/javascript"></script>
<script>

  import Emailapi from '@/services/api/emails'
  import Signinapi from '@/services/api/signin'
  import Userapi from '@/services/api/users'
  import {bus} from '@/main'
  import popoverpassword from './popoverpassword'
  import uuid from 'uuid'

  export default{
    data(){
     return{
      userid : null,
      status : "idle",
      passwordresetok : false,
      password: { emailisvalid:"", error:"", password2:{ error:"", value:"" }, password3:{ error:"", value:"" } },
      emailisvalid : true,
      mobiledevise:  window.innerwidth <= 576 ? 'right' : 'topright',
      setpassword:false,
      useremail:""
    }
  },
  components:{popoverpassword},
  filters: {},
  mounted() {

    if(this.$route.query.email){
       this.useremail = this.$route.query.email.toLowerCase()
    }
   

    if( this.$route.name == 'setpassword' ){
      this.setpassword = true
    }else{
      this.setpassword = false
      this.checkifvalid()
    }

    
    document.title ='Flowe | Change password'
    $('#preloader').addClass('hide')
  },
  methods: {

   /* usetoken : function( token ){

      let self = this

      this.pageloaded = false

      if( token != "" ){

        Userapi.Details( token )
        .then( function(response){

          if( response.data.ok ){

            //localStorage.token = token
            //localStorage.userdetails = JSON.stringify( response.data.user )
            ////location.href="/boards"

            self.$cookie.set('usertoken', response.data.response);
            self.$router.push('/boards')

          }else{
            self.pageloaded = true
          }

          $('#preloader').addClass('hide')

        })
        .catch( () => {
                 //self.pageloaded = true

                 $('#preloader').addClass('hide')
               })
        .finally( () => {
                 //self.pageloaded = true

                 $('#preloader').addClass('hide')
               })


      }else{
        this.pageloaded = true
        $('#preloader').addClass('hide')
      }



    },*/

    passwordSetdo(){
 
      this.status = "working"
      let self = this;

      var authid = self.$route.query.uid
      var emailid = self.$route.query.eid

            self.password.password2.error = ""
            self.password.password3.error = ""
            self.password.error = ""

            //v=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl&eid=TTDfjzv_sCiVbPsx8IgWaWbrnCnoJ5G9kzRAxDnhXP2ZHhRLXhHtGXFuFKNGQLan&password2=aasdf123&password3=asdf1234

            Signinapi.Passwordchange( { act:"setnewpassword", v:authid, eid:emailid, password2:self.password.password2.value, password3:self.password.password3.value } )
            .then( function(response){

                  self.status = "idle"

                  //console.log( response.data.responses )

                  if( response.data.ok ){     
                    var str = 'Your password was successfully updated.'
                    var str1 = 'Password changed'

                    bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true})

                    Emailapi.Passwordchanged( response.data.iid )
                    
                    //self.usetoken( response.data.iid )
                    self.$cookie.set('usertoken', response.data.iid);
                    //self.$router.push('/boards')
                    let  orgkey = response.data.myorg.key
                    self.$cookie.set('organizationkey', orgkey );

                    setTimeout( function(){
                       //location.href="/boards"
                        location.href="/"+orgkey+"/projects"
                    }, 1500)

                  

                  }else{

                    self.password.error = response.data.message

                    for( var i in response.data.responses ){
                      if( response.data.responses[i].field == 'password2' ){
                        self.password.password2.error = response.data.responses[i].value
                      }

                      if( response.data.responses[i].field == 'password3' ){
                        self.password.password3.error = response.data.responses[i].value

                      }
                    }                    

                  }

                })
            .catch(error => console.log(error))
            .finally( () => {
              self.status = 'idle'
            })


          },

    passwordResetdo(){
 
      this.status = "working"
      let self = this;

      this.checkifvalid( function(){
            

            var authid = self.$route.query.uid
            var emailid = self.$route.query.eid

            self.password.password2.error = ""
            self.password.password3.error = ""
            self.password.error = ""

            //v=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl&eid=TTDfjzv_sCiVbPsx8IgWaWbrnCnoJ5G9kzRAxDnhXP2ZHhRLXhHtGXFuFKNGQLan&password2=aasdf123&password3=asdf1234

            Signinapi.Passwordchange( { v:authid, eid:emailid, password2:self.password.password2.value, password3:self.password.password3.value } )
            .then( function(response){

                  self.status = "idle"

                  //console.log( response.data.responses )

                  if( response.data.ok ){     
                    var str = 'Your password was successfully updated.'
                    var str1 = 'Password changed'


                    bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true})




                    Emailapi.Passwordchanged( response.data.iid )
                    
                    //self.usetoken( response.data.iid )

                    self.$cookie.set('usertoken', response.data.iid);
                    //self.$router.push('/boards')
                   

                    let  orgkey = response.data.myorg.key
                    self.$cookie.set('organizationkey', orgkey );

                    setTimeout( function(){
                       //location.href="/boards"
                        location.href="/"+orgkey+"/projects"
                    }, 1500)

                  

                  }else{

                    self.password.error = response.data.message

                    for( var i in response.data.responses ){
                      if( response.data.responses[i].field == 'password2' ){
                        self.password.password2.error = response.data.responses[i].value
                      }

                      if( response.data.responses[i].field == 'password3' ){
                        self.password.password3.error = response.data.responses[i].value

                      }
                    }                    

                  }

                })
            .catch(error => console.log(error))
            .finally( () => {
              self.status = 'idle'
            })

          })


          },
          checkifvalid( callback ){

            var authid = this.$route.query.uid
            var emailid = this.$route.query.eid

            let self = this;

            Signinapi.Passwordchangecheck( authid, emailid )
            .then( function(response){


              if( response.data.ok ){     

                if( callback ){
                  callback()
                }
              }else{
                self.emailisvalid = false
              }

            })
            .catch(error => console.log(error))
            .finally( () => {

              self.status = 'idle'

                  //do everything
                  //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
                })


          }
        }
      }
    </script>